import axios from 'axios';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

import { getCookie } from 'preferences/cookie/cookie';

/* ====================================================================== 로컬 */
export const REDIRECT_URL = '/oauth2/redirect';

// >> 주석해야 함!!
// export const API_BASE_URL = 'http://localhost:8080';
// export const CLIENT_BASE_URL = 'http://localhost:3000';

/* ====================================================================== API_BASE_URL */
/* 로컬 일반 SSL */
// export const API_BASE_URL = 'https://lvh.me:8000';

/* 테스트 서버 */
// export const API_BASE_URL = 'https://ifistest.link:8080';

/* 서버 */
export const API_BASE_URL = 'https://ifis.pro:8080';

// >> 주석 풀어야 함!!
export const CLIENT_BASE_URL = API_BASE_URL.replace(':8080', '');

/* ====================================================================== GOOGLE_AUTH_URL */
export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + CLIENT_BASE_URL + REDIRECT_URL;
export const CUSTOM_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + CLIENT_BASE_URL;

/* ====================================================================== axios */
export const ACCESS_TOKEN = 'accessToken';
export const AXIOS_BASE_URL = API_BASE_URL + '/api';
export const AXIOS_BASE_HEADERS = {
  headers: { Authorization: getCookie(ACCESS_TOKEN) },
};

axios.defaults.baseURL = '';
axios.defaults.headers.common['Authorization'] = getCookie(ACCESS_TOKEN);

/* ====================================================================== stomp */
// const STOMP_URL = API_BASE_URL + '/stomp';
const STOMP_URL = CLIENT_BASE_URL + '/stomp';
const STOMP_HEADERS = getCookie(ACCESS_TOKEN) ? getCookie(ACCESS_TOKEN).replace('Bearer ', '') : null;

/* 웹소켓 */
export const WS_CLIENT = new Client({
  webSocketFactory: () => new SockJS(STOMP_URL),
  // webSocketFactory: () => new SockJS(STOMP_URL, null, { withCredentials: false }),

  connectHeaders: {
    'auth-token': STOMP_HEADERS,
    'Authorization': getCookie(ACCESS_TOKEN) || null,
  },

  reconnectDelay: 5000,
  heartbeatIncoming: 4000,
  heartbeatOutgoing: 4000,

  debug: (str) => { console.log(`${str}`); },
});


