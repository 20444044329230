import React from 'react';

import { checkEmptyNull } from 'components/checkValues/checkValues';

import { TmpHmdContents, TmpHmpIconWrapper, OrderTmpHmd } from 'pages/safetyManagement/SafetyManagement.style';
import { CiTempHigh } from "react-icons/ci";
import { WiHumidity } from "react-icons/wi";

const TmpHmpContent = ({ sensorData, sensorView }) => {
  console.log('sensorData', sensorData.length);
  const matchingData = sensorData.filter((data) => data.name === sensorView,);
  const remainingData = sensorData.filter((data) => data.name !== sensorView,);

  const renderSensorData = (sensorData, isEmphasized) => {
    const data_temp = sensorData.temp
    const data_mo = sensorData.hum
    
    return (
      <TmpHmdContents
        key={sensorData.name}
        className={isEmphasized ? 'emphasis' : ''}
      >
        {sensorData.length = 1 && sensorData.name === 'temp' ? null : <h4>{sensorData.name}</h4>}
        <div>
          <TmpHmpIconWrapper>
            {isEmphasized ? <CiTempHigh className="tmp-icon" /> : null}
            <p className={isEmphasized ? 'emphasis' : ''}>
              {(checkEmptyNull(data_temp, 0) * 1).toLocaleString()} ℃
            </p>
          </TmpHmpIconWrapper>
          <TmpHmpIconWrapper>
            {isEmphasized ? <WiHumidity className="hmd-icon" /> : null}
            <p className={isEmphasized ? 'emphasis' : ''}>
              {(checkEmptyNull(data_mo, 0) * 1).toLocaleString()} %
            </p>
          </TmpHmpIconWrapper>
        </div>
      </TmpHmdContents>
    );
  };

  return (
    <div>
      {matchingData.map((sensorData) => renderSensorData(sensorData, true))}
      {remainingData.length > 0 && (
        <OrderTmpHmd rightSection={remainingData.length}>
          {remainingData.map((sensorData) => renderSensorData(sensorData, false))}
        </OrderTmpHmd>
      )}
    </div>
  );
};

export default TmpHmpContent;