import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BigNumber from 'bignumber.js';
import styled from 'styled-components';

import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { contractApi } from 'api/apis/contractApi';
import { elementApi } from 'api/apis/elementApi';
import { procurementApi } from 'api/apis/procurementApi';
import { workOrderLogApi } from 'api/apis/workOrderLogApi';

import { checkEmptyNull, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
import { DateFormat } from 'components/format/DateFormat';

import CommaNum from 'components/format/CommaNum';
import GridMonitoringBody from 'components/layouts/body/GridMonitoringBody';
import Header from 'components/header/Header';
import { shipmentApi } from 'api/apis/shipmentApi';

const MonitoringBody = styled.div`
  height: ${props => {
    return props.headerStatus === 'headerStatus' ? 'calc(100svh - 60px)' : '100svh';
  }} !important;

  & .subContents {
    box-sizing: border-box;
    margin: unset;
    padding: 24px;
  }
`;

const MainContents = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  width: 100%;
`;

const StockTableSection = styled.div`
  height: 100%;
  overflow: scroll;
  position: static;
  width: 100%;

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}

  table {
    background-color: var(--white);
    border-collapse: collapse;
    font-weight: 400;
    min-width: 100%;
    height: 100%;
  }
  thead {
    background-color: var(--MainNavy);
    color: var(--white);
    font-size: 14px;
    height: 40px;
    left: 0px;
    position: sticky;
    top: 0px;
    z-index: 1;
  }
  td, th {
    box-sizing: border-box;
    padding: 5px 15px;
    max-width: 300px;
    min-width: 200px;
    text-align: center;
    white-space: normal;
    word-break: break-all;
  }
  tr {border-bottom: 1px solid var(--gray-200);}
`;
const StockTable = styled.table`
  tr {border: none;}
  td, th {min-width: 100px;}
  thead tr:nth-child(2) th {height: 40px;}
  tbody tr {border-bottom: 1px solid var(--MainNavy);}
`;
const StockContents = styled.div`
  box-sizing: border-box;
  height: 100%;
  overflow: auto;

  ${(props) => {
    return props._AllMonitoringStatus === true ? 'width: 35%;' : 'width: 100%;';
  }}

  &::-webkit-scrollbar {height: 12px; width: 12px;}
  &::-webkit-scrollbar-track {background-color: var(--gray-100);}
  &::-webkit-scrollbar-thumb {background-color: var(--gray-400); border: 2px solid var(--gray-100); border-radius: 6px;}
`;

const ProductionStatus = styled.div`
  background-color: var(--Bg);
  height: 100%;
  overflow: auto;

  ${(props) => {
    return props._AllMonitoringStatus === true ? 'width: 65%;' : 'width: 100%;';
  }}

  & .TableSection {
    height: 100%;
    margin: 0px auto;
    overflow: scroll;
    width: 100%;
  }
`;
const OnlyProductionStatus = styled.div`
  background-color: var(--Bg);
  height: 100%;
  overflow: auto;

  ${(props) => {
    return props._AllMonitoringStatus === true ? 'width: 65%;' : 'width: 100%;';
  }}

  & .TableSection {
    height: 100%;
    margin: 0px auto;
    overflow: scroll;
    width: 100%;
  }
`;

const MonitoringSection = styled.div`
  background-color: var(--white);
  box-sizing: border-box;
  display: grid;
  height: 100%;
  grid-template-rows: 80px auto 60px;
  padding: 0px 30px;
  width: 100%;
`;
const TopSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;
const SecureTitle = styled.h2``;
const Today = styled.div`
  position: absolute;
  top: 30px;
  right: 20px;
`;

const MainSection = styled.div`
  border: 5px solid var(--MainNavy);
  box-sizing: border-box;
  display: grid;
  grid-template-rows: 50% 50%;
  height: 100%;
  width: 100%;
`;

const BottomSection = styled.div`
  align-items: center;
  column-gap: 10px;
  display: flex;
  height: 100%;
  justify-content: center;

  & h2 {font-size: 30px;}
`;
const LogoBox = styled.div`
  align-items: center;
  column-gap: 5px;
  display: flex;
  justify-content: center;
`;
const Logo = styled.img`
  align-self: center;
  background-color: var(--Bg);
  border-radius: 50%;
  height: 30px;
  transform: translateY(2px);
  width: 30px;
`;

const GSWorkingTableStyle = styled.table`
  background-color: var(--white);
  border-collapse: collapse;
  font-weight: 400;
  min-height: 100%;
  min-width: 100%;

  thead {
    background-color: var(--MainNavy);
    color: var(--white);
    font-size: 14px;
    height: 40px;
    left: 0px;
    position: sticky;
    top: 0px;
    z-index: 1;
  }
  td, th {
    box-sizing: border-box;
    padding: 5px 15px;
    text-align: center;
    white-space: normal;
    word-break: break-all;
  }
  td, th {border: 1px solid var(--gray-200);}
  tr.titleRow {
    background-color: var(--MainNavy);
    color: var(--white);
    font-size: 14px;
    height: 40px;
  }
  td.titleRow {
    background-color: var(--MainNavy);
    color: var(--white);
    font-size: 14px;
  }
  td.processCell {
    padding: unset;
    position: relative;

    & div {height: 100%;}
  }
`;
const ProcessBG = styled.div`
  background-color: var(--gray-100);
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
`;
const ProcessLineSection = styled.div`
  display: grid;
  grid-template-columns: repeat(10, 10%);
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
`;
const ProcessLine = styled.div`
  border: 1px dashed var(--white);
  box-sizing: border-box;
  width: 100%;
`;
const ProcessBar = styled.div`
  background: ${(props) => {
    return props.type === '입고' ? 'linear-gradient(90deg, rgba(212,255,175,1) 0%, rgba(114,222,30,1) 100%)'
      : props.type === '가공' ? 'linear-gradient(90deg, rgba(255,214,175,1) 0%, rgba(255,111,49,1) 100%)'
        : props.type === '제작' ? 'linear-gradient(90deg, rgba(255,247,194,1) 0%, rgba(255,239,0,1) 100%)'
          : props.type === '출고' ? 'linear-gradient(90deg, rgba(149,217,255,1) 0%, rgba(0,146,255,1) 100%)'
            : 'unset';
  }};
  left: 0px;
  position: absolute;
  top: 0px;
`;

/* =============================================================================================================== GSWorkingTable */

const GSProcess = (props) => {
  return (
    <ProcessBG>
      <ProcessBar type={props.type} style={{ width: props.part + '%' }}></ProcessBar>
      <ProcessLineSection>
        <ProcessLine />
        <ProcessLine />
        <ProcessLine />
        <ProcessLine />
        <ProcessLine />
        <ProcessLine />
        <ProcessLine />
        <ProcessLine />
        <ProcessLine />
        <ProcessLine />
      </ProcessLineSection>
    </ProcessBG>
  );
};

/* =============================================================================================================== GSWorkingTable */

const GSWorkingTable = (content, contractid) => {
  /* ====================================================================== #1 */
  const { userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_quantityProcessRate, setQuantityProcessRate] = useState(0);

  const [_shipmentProgressRate, setShipmentProgressRate] = useState(0);

  const [_CalBl, setCalBl] = useState(0);
  const [_CalCp, setCalCp] = useState(0);
  const [_CalCl, setCalCl] = useState(0);
  const [_CalOutPut, setCalOutPut] = useState(0);
  const [_totalWeight, setTotalWeight] = useState([]);
  // console.table({
  //   _quantityProcessRate: _quantityProcessRate,
  //   가공_CalCp: _CalCp,
  //   제작_CalCl: _CalCl,
  //   출하값_CalOutPut: _CalOutPut,
  //   총중량_totalWeight: _totalWeight,
  // });

  /* ====================================================================== #3 */
  useEffect(() => {
    (async () => {
      await searchAllprocurement();
      await searchAllWorkOrderLog();
    })();
  }, []);

  /* ====================================================================== #4 */
  const searchAllprocurement = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      contractId: content.content.contractId,
    };
    await procurementApi.searchProcurement('', BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('procurementApi.searchProcurement : ', response);

      const procurement = response.data.content[0];
      if (!checkNullObject(procurement, false)) return;
      setProcurement(procurement);
    });
  };
  const setProcurement = (Result) => {
    const QuantityArray = [];
    const StoreQuantityArray = [];
    Result.procurementElementList.forEach((thisItem) => {
      QuantityArray.push(Number(thisItem.quantity));
      StoreQuantityArray.push(BigNumber(checkEmptyNull(thisItem.quantity, 0)).minus(BigNumber(checkEmptyNull(thisItem.storeQuantity, 0))).toNumber()); // -> 기입고 수량
    });
    const totalQuantity = parseFloat(Number(QuantityArray.reduce((prv, curr) => prv + curr, 0)));
    const totalstoreQuantity = parseFloat(Number(StoreQuantityArray.reduce((prv, curr) => prv + curr, 0)));
    let quantityProcessRate = (totalstoreQuantity / totalQuantity) * 100;
    if (isNaN(Number(quantityProcessRate))) quantityProcessRate = 0;
    setQuantityProcessRate(quantityProcessRate);
  };

  const getShippedProcessLogIdsByWorkOrderLogs = async (targetWorkOrderLogIds = []) => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };

    // ✅ 최신 데이터부터 내림차순 정렬
    const paging = '?page=0&size=1000&sort=id,DESC';
  
    console.log('📡 [1] shipmentApi 요청 시작 (내림차순)...');
    const response = await shipmentApi.searchShipment(paging, BodyToPost);
    
    if (!response || !response.data || !response.data.content) {
      console.warn('❌ [2] shipmentApi 응답 없음 또는 content 없음');
      return [];
    }
  
    const allShipments = response.data.content;
    console.log(`📥 [3] shipment 수신 완료. 총 ${allShipments.length}건`);
  
    const matchedProcessLogIds = [];
  
    allShipments.forEach((shipment, idx) => {
      // console.log(`\n🧾 [4] shipment[${idx}] 코드: ${shipment.shipmentCode}, 항목 수: ${shipment.shipmentElementList?.length || 0}`);
  
      shipment.shipmentElementList.forEach((element, elIdx) => {
        const parsed = checkNullParse(element.customizedContent, {});
        const wId = parsed.workOrderLogId;
        const pId = parsed.processLogId;
  
        // console.log(typeof wId)
        // console.log(`   📦 [5.${elIdx}] element`, {
        //   workOrderLogId: wId,
        //   processLogId: pId,
        // });
  
        // // 필터 조건 확인
        // if (targetWorkOrderLogIds.includes(wId) && pId !== undefined) {
        //   matchedProcessLogIds.push(pId);
        //   console.log(`   ✅ [6.${elIdx}] 필터 통과 → 추가됨`);
        // } else {
        //   console.log(`   🚫 [6.${elIdx}] 필터 통과 실패`);
        // }
        const wIdNum = Number(wId);
        const targetIds = targetWorkOrderLogIds.map(Number);

        if (targetIds.includes(wIdNum) && pId !== undefined) {
          matchedProcessLogIds.push(pId);
          console.log(`✅ 필터 통과: wId = ${wId} → 추가됨`);
        } else {
          // console.warn(`🚫 필터 실패: wId = ${wId}, targetWorkOrderLogIds =`, targetWorkOrderLogIds);
        }
      });
    });
  
    console.log('📊 [7] 최종 matchedProcessLogIds:', matchedProcessLogIds);
    return matchedProcessLogIds;
  };
  
  const searchAllWorkOrderLog = async () => {
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      contractId: content.content.contractId,
    };
    await workOrderLogApi.searchWorkOrderLog('', BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('workOrderLogApi.searchWorkOrderLog : ', response);

      const workOrderLogs = response.data.data.content[0];
      if (!checkNullObject(workOrderLogs, false)) return;

      const workOrderLog = workOrderLogs.processLogList;
      if (!checkNullObject(workOrderLog, false)) return;
      // setWorkOrderLog(workOrderLog);

      console.log('workOrderLogApi.data : ', response.data.data.content[0]);
      const workOrderLogId = workOrderLogs.workOrderLogId; // 👈 여기 있음!
      setWorkOrderLog(workOrderLog, workOrderLogId); // ✅ workOrderLogId 같이 넘기기
    });
  };
  const setWorkOrderLog = async (Result, workOrderLogId) => {
    console.log('Result : ', Result);
    // 1. cp cl 어레이 만들기
    const BlArray = Result.filter((thisBl) => thisBl.processTypeName === 'BL');
    const CpArray = Result.filter((thisCp) => thisCp.processTypeName === 'CP');
    const ClArray = Result.filter((thisCl) => thisCl.processTypeName === 'CL');
    // console.log('📦 ClArray.length =', ClArray.length);
    // console.log('📦 ClArray 샘플 =', ClArray.slice(0, 3));


    const endBl = BlArray.filter((thisEndBl) => thisEndBl.processStatus === 'end');
    let CalBl = parseFloat((endBl.length / BlArray.length) * 100);
    if (isNaN(Number(CalBl))) CalBl = 0;
    // 2. 가공 값 구하기 프로세스로그리스트랭스 분의 프로세스스테터스가 엔드인 cp , 제작, 출고
    const endCp = CpArray.filter((thisEndCp) => thisEndCp.processStatus === 'end');
    let CalCp = parseFloat((endCp.length / CpArray.length) * 100);
    if (isNaN(Number(CalCp))) CalCp = 0;
    // 3. 제작 값 구하기
    const endCl = ClArray.filter((thisEndCl) => thisEndCl.processStatus === 'end');
    let CalCl = parseFloat((endCl.length / ClArray.length) * 100);
    if (isNaN(Number(CalCl))) CalCl = 0;
    // 4. 발주처럼 foreach돌려서 하나는 숫자 인보이스스테터스가 들어갈 변수(0) 하나는 빈어레이 중량 값이 들어가는 스테이터스
    // 출고율 계산 > 안됨 없는 키 값
    let shipmentStatus = 0;
    const weightStatus = [];
    ClArray.forEach((thisItem) => {
      if (!checkNullParse(thisItem.processCustomizedContent, false)) return;
      const parseData = JSON.parse(thisItem.processCustomizedContent);
      // console.log('커스텀 데이터 : ', parseData)
      if (parseData.weight !== undefined) {
        // const pushNum = isNaN(Number(parseData.weight)) === true ? 0 : Number(parseData.weight);
        const elementWeight = BigNumber(checkEmptyNull(parseData.weight, 0)).multipliedBy(BigNumber(checkEmptyNull(parseData.quantity, 0))).toNumber();

        weightStatus.push(elementWeight);
      }

      if (parseData.shipmentStatus !== undefined && parseData.shipmentStatus === 'end') {
        shipmentStatus++;
      }
    });
    // 5. 출하 값 구하기 프로세스로그리스트랭스 분의 인보이스스테터스가 들어갈 변수(0).
    let CalOutPut = parseFloat((shipmentStatus / ClArray.length) * 100);
    if (isNaN(Number(CalOutPut))) {
      CalOutPut = 0;
    }
    // 6. 어레이 리듀스해서 총 중량 적기
    let totalWeight = parseFloat(Number(weightStatus.reduce((prv, curr) => prv + curr, 0)));
    if (isNaN(Number(totalWeight))) {
      totalWeight = 0;
    }

    console.log('weightStatus : ', weightStatus);

    // 출고(하)율 계산 (새로 추가된 핵심 로직)
    const allWorkOrderLogIds = [workOrderLogId]; // ✅ workOrderLogId 하나만으로 비교

    console.log('🔎 추출된 workOrderLogIds (원본):', allWorkOrderLogIds);
  
    const allProcessLogIds = ClArray.map((item) => item.processLogId).filter(Boolean);
    console.log('allProcessLogIds:', allProcessLogIds)

    const shippedProcessLogIds = await getShippedProcessLogIdsByWorkOrderLogs(allWorkOrderLogIds);
  
    const matchedShipped = allProcessLogIds.filter((pid) =>
      shippedProcessLogIds.includes(pid)
    );

    const shipmentRate = parseFloat((matchedShipped.length / allProcessLogIds.length) * 100) || 0;
  

    // check
    setCalBl(CalBl);
    setCalCp(CalCp);
    setCalCl(CalCl);
    setCalOutPut(CalOutPut);
    setTotalWeight(totalWeight);
    setShipmentProgressRate(shipmentRate);
  };

  /* ====================================================================== #5 */

  /* ====================================================================== #6 */

  return (
    <GSWorkingTableStyle>
      <thead>
        <tr>
          <th style={{ minWidth: '100px', width: '190px' }}>수주코드</th>
          <th colSpan={3} style={{ textAlign: 'left' }}>공사명</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{content.content.contractCode}</td>
          <td style={{ textAlign: 'left' }}>{content.content.contractName}</td>
          <td className="titleRow">총 중량</td>
          <td>{checkEmptyNull(_totalWeight, 0).toLocaleString()}</td>
        </tr>
        <tr className="titleRow">
          <td style={{ minWidth: '100px', width: '100px' }}>공정</td>
          <td>진행사항</td>
          <td style={{ minWidth: '100px', width: '100px' }}>공정률</td>
          <td style={{ minWidth: '130px', width: '130px' }}>{userReducer.endDateName[1]}</td>
        </tr>
        <tr>
          <td>입고</td>
          <td className="processCell"><GSProcess type="입고" part={_quantityProcessRate} /></td>
          <td>{`${Math.round(_quantityProcessRate)}%`}</td>
          <td rowSpan={4}>{DateFormat(checkEmptyNull(content.content?.scheduledEndDate2, ''))}</td>
        </tr>
        <tr>
          <td>가공</td>
          <td className="processCell"><GSProcess type="가공" part={_CalCp} /></td>
          <td>{`${Math.round(_CalCp)}%`}</td>
        </tr>
        <tr>
          <td>제작</td>
          <td className="processCell"><GSProcess type="제작" part={_CalCl} /></td>
          <td>{`${Math.round(_CalCl)}%`}</td>
        </tr>
        {/* <tr key={contractid + '_출고'}>
          <td>출고</td>
          <td className="processCell"><GSProcess type="출고" part={_CalOutPut} /></td>
          <td>{`${Math.round(_CalOutPut)}%`}</td>
        </tr> */}
        <tr>
          <td>출고</td>
          <td className="processCell"><GSProcess type="출고" part={_shipmentProgressRate} /></td>
          <td>{`${Math.round(_shipmentProgressRate)}%`}</td>
        </tr>
      </tbody>
    </GSWorkingTableStyle>
  );
};

/* =============================================================================================================== GSTotalMonitoring */

const GSTotalMonitoring = () => {
  /* ========================================================================= #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { searchReducer, userReducer, workReducer } = useSelector((state) => state);

  /* ========================================================================= #2 */
  const [_onload, setOnload] = useState('unload');

  const [_MaterialList, setMaterialList] = useState([]);
  const [_ContractList, setContractList] = useState([]);

  // 누른 버튼에 따라 보이는 화면
  const [_OnlyStockContentStatus, setOnlyStockContentStatus] = useState(false);
  const [_OnlyProductContentStatus, setOnlyProductContentStatus] = useState(false);
  const [_AllMonitoringStatus, setAllMonitoringStatus] = useState(true);

  const [_stockPage, setStockPage] = useState({ page: 0, totalCount: 0 });
  const [_productionPage, setProductionPage] = useState({ page: 0, totalCount: 0 });
  const [_stockTotalPage, setStockTotalPage] = useState(0);
  const [_productionTotalPage, setProductionTotalPage] = useState(0);

  /* ========================================================================= #3 */
  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));

    setMaterialTable();
    searchAllContract();

    const monitoringInterval = setInterval(async () => {
      setStockPage((prev) => {
        if (prev.page >= parseFloat(_stockTotalPage / 20) - 1) return { ...prev, page: 0 };
        return { ...prev, page: prev.page + 1 };
      });
      setProductionPage((prev) => {
        if (prev.page >= parseFloat(_productionTotalPage / 2) - 1) return { ...prev, page: 0 };
        return { ...prev, page: prev.page + 1 };
      });

      await setMaterialTable();
      await searchAllContract();
    }, 60000);

    setOnload('loaded');
    return () => clearInterval(monitoringInterval);
  }, []);

  useEffect(() => { }, [_MaterialList, _ContractList])

  /* ========================================================================= #4 */
  // 자재를 불러오는 함수
  const setMaterialTable = async () => {
    const paging = `?page=${_stockPage.page}&size=20&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
      elementType: 'material',
    };
    await elementApi.searchElementStockList(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('elementApi.searchElementStockList : ', response);

      setMaterialList(response.data.content);
      setStockTotalPage(response.data.totalElements);
    });
  };

  // 수주를 불러오는 함수 ( get )
  const searchAllContract = async () => {
    const paging = `?page=${_productionPage.page}&size=2&sort=id,DESC`;
    const BodyToPost = {
      companyId: userReducer.company.companyId,
    };
    await contractApi.searchContract(paging, BodyToPost).then((response) => {
      if (response === undefined) return;
      console.log('contractApi.searchContract : ', response);

      setContractList(response.data.content);
      setProductionTotalPage(response.data.totalElements);
    });
  };

  /* ========================================================================= #5 */
  //재고 버튼을 눌렀을때
  const onlyStock = () => {
    setOnlyStockContentStatus(true);
    setOnlyProductContentStatus(false);
    setAllMonitoringStatus(false);
  };

  //작업현황 버튼을 눌렀을때
  const onlyProduct = () => {
    setOnlyProductContentStatus(true);
    setOnlyStockContentStatus(false);
    setAllMonitoringStatus(false);
  };

  // 전체보기 버튼을 눌렀을때
  const allMonitoring = () => {
    setOnlyStockContentStatus(false);
    setOnlyProductContentStatus(false);
    setAllMonitoringStatus(true);
  };

  /* ========================================================================= #6 */
  const [floatingMenu, setFloatingMenu] = useState(false);
  const floatingBtn = () => { setFloatingMenu(!floatingMenu); }

  const [headerStatus, setHeaderStatus] = useState(false)
  const clickHeader = (e) => { setHeaderStatus(!headerStatus) }

  return (
    <>
      <GridMonitoringBody
        header={headerStatus ? (<Header />) : <></>}
        contents={
          <>
            <MonitoringBody className='monitoringBody' headerStatus={headerStatus ? 'headerStatus' : ''}>
              {_AllMonitoringStatus === true ? (
                <MainContents>
                  <StockContents _AllMonitoringStatus={_AllMonitoringStatus}>
                    <StockTableSection>
                      <StockTable>
                        <thead>
                          <tr>
                            <th colSpan={5} style={{ backgroundColor: 'var(--ThirdBlue)' }}>자재 재고현황</th>
                          </tr>
                          <tr>
                            <th>물품코드</th>
                            <th>물품이름</th>
                            <th>당일입고</th>
                            <th>당일투입</th>
                            <th>현재고</th>
                          </tr>
                        </thead>
                        <tbody>
                          {_MaterialList.map((thisRowData, index) => {
                            return (
                              <tr key={index + '_materials_all'}>
                                <td>{thisRowData.elementCode}</td>
                                <td className={checkEmptyNull(thisRowData.elementName, '').startsWith('*') ? 'outsourcingText' : null}>{thisRowData.elementName}</td>
                                <td>
                                  <CommaNum displayType={'text'} num={thisRowData.todayInStock} />
                                  {` ${thisRowData.elementUnit}`}
                                </td>
                                <td>
                                  <CommaNum displayType={'text'} num={searchReducer.productType === 'product' ? thisRowData.todayOutStock : thisRowData.todayPutStock} />
                                  {` ${thisRowData.elementUnit}`}
                                </td>
                                <td className={(checkEmptyNull(thisRowData.elementStock, 0) * 1) <= checkEmptyNull(thisRowData.elementSafeStock, 0) * 1 ? 'dangerStock' : 'safeStock'}>
                                  <CommaNum displayType={'text'} num={thisRowData.elementStock} />
                                  {` ${thisRowData.elementUnit}`}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </StockTable>
                    </StockTableSection>
                  </StockContents>

                  <ProductionStatus _AllMonitoringStatus={_AllMonitoringStatus}>
                    <MonitoringSection>
                      <TopSection>
                        <SecureTitle>작은 관심과 배려는 재해예방의 첫 걸음 입니다.</SecureTitle>
                        <Today>{DateFormat(new Date())}</Today>
                      </TopSection>

                      <MainSection>
                        {_ContractList.map((contract, index) => {
                          return (
                            <GSWorkingTable
                              key={index + '_GSWorkingTable'}
                              content={contract}
                              contractid={contract.contractId}
                            ></GSWorkingTable>
                          );
                        })}
                      </MainSection>

                      <BottomSection>
                        <LogoBox>
                          <Logo></Logo>
                          <h3>(주)경서</h3>
                        </LogoBox>
                        <LogoBox>
                          <Logo></Logo>
                          <h3>EQ구조엔지니어링(주)</h3>
                        </LogoBox>
                      </BottomSection>
                    </MonitoringSection>
                  </ProductionStatus>
                </MainContents>
              ) : null}

              {(_OnlyStockContentStatus === true || _OnlyProductContentStatus === true) ? (
                <MainContents>
                  {_OnlyStockContentStatus === true ? (
                    <StockContents _AllMonitoringStatus={_AllMonitoringStatus}>
                      <StockTableSection>
                        <StockTable>
                          <thead>
                            <tr>
                              <th colSpan={5} style={{ backgroundColor: 'var(--ThirdBlue)' }}>자재 재고현황</th>
                            </tr>
                            <tr>
                              <th>물품코드</th>
                              <th>물품이름</th>
                              <th>당일입고</th>
                              <th>당일투입</th>
                              <th>현재고</th>
                            </tr>
                          </thead>
                          <tbody>
                            {_MaterialList.map((thisRowData, index) => {
                              return (
                                <tr key={index + '_materials_all'}>
                                  <td>{thisRowData.elementCode}</td>
                                  <td className={checkEmptyNull(thisRowData.elementName, '').startsWith('*') ? 'outsourcingText' : null}>{thisRowData.elementName}</td>
                                  <td>
                                    <CommaNum displayType={'text'} num={thisRowData.todayInStock} />
                                    {` ${thisRowData.elementUnit}`}
                                  </td>
                                  <td>
                                    <CommaNum displayType={'text'} num={searchReducer.productType === 'product' ? thisRowData.todayOutStock : thisRowData.todayPutStock} />
                                    {` ${thisRowData.elementUnit}`}
                                  </td>
                                  <td className={(checkEmptyNull(thisRowData.elementStock, 0) * 1) <= checkEmptyNull(thisRowData.elementSafeStock, 0) * 1 ? 'dangerStock' : 'safeStock'}>
                                    <CommaNum displayType={'text'} num={thisRowData.elementStock} />
                                    {` ${thisRowData.elementUnit}`}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </StockTable>
                      </StockTableSection>
                    </StockContents>
                  ) : null}

                  {_OnlyProductContentStatus === true ? (
                    <OnlyProductionStatus _AllMonitoringStatus={_AllMonitoringStatus}>
                      <ProductionStatus _AllMonitoringStatus={_AllMonitoringStatus}>
                        <MonitoringSection>
                          <TopSection>
                            <SecureTitle>작은 관심과 배려는 재해예방의 첫 걸음 입니다.</SecureTitle>
                            <Today>{DateFormat(new Date())}</Today>
                          </TopSection>

                          <MainSection>
                            {_ContractList.map((contract, index) => {
                              return (
                                <GSWorkingTable
                                  key={index + '_GSWorkingTable'}
                                  content={contract}
                                  contractid={contract.contractId}
                                ></GSWorkingTable>
                              );
                            })}
                          </MainSection>

                          <BottomSection>
                            <LogoBox>
                              <Logo></Logo>
                              <h3>(주)경서</h3>
                            </LogoBox>
                            <LogoBox>
                              <Logo></Logo>
                              <h3>EQ구조엔지니어링(주)</h3>
                            </LogoBox>
                          </BottomSection>
                        </MonitoringSection>
                      </ProductionStatus>
                    </OnlyProductionStatus>
                  ) : null}
                </MainContents>
              ) : null}
            </MonitoringBody>
          </>
        }
      />

      {/* 플로팅 메뉴 */}
      {floatingMenu ? (
        <div className='drop-items'>
          <button onClick={clickHeader} data-name='on'>전체메뉴 {headerStatus ? '닫기' : '열기'}</button>
          <hr />
          <button onClick={onlyStock}>재고현황</button>
          <button onClick={onlyProduct}>작업현황</button>
          <button onClick={allMonitoring}>전체보기</button>
          <hr />
          <button onClick={() => setFloatingMenu(false)}>닫기</button>
        </div>
      ) :
        <div className='drop-items' style={{ padding: '16px 12px', borderRadius: '38px' }}>
          <button onClick={floatingBtn}>메뉴</button>
        </div>
      }
    </>
  );
};

export default GSTotalMonitoring;
