import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';

import { checkEmptyNull, checkNullArray } from 'components/checkValues/checkValues';

import TableSection from 'components/layouts/table/TableSection';

const KPITable = (props) => {
  const { unit, contents } = props;
  const [_current, setCurrent] = useState(); // 현재
  const [_target, setTarget] = useState(); // 목표
  const [_performance, setPerformance] = useState(); // 누적 실적
  const [_achievement, setAchievement] = useState(); // 향상률
  const [_goalRate, setGoalRate] = useState(); // 목표달성율

  useEffect(() => {
    /*
    향상률 = ((실적 - 현재) / 현재) * 100 >증가목표 / ((현재 - 실적) / 현재) * 100 >감소목표
    목표달성률 = 실적 / 목표 * 100 >증가목표 / 목표 / 실적 * 100 >감소목표
    */
    const current = checkEmptyNull(unit.current, 0) * 1;
    const target = checkEmptyNull(unit.target, 0) * 1;
    let performance;
    if (unit.realDtlCd === 'shorten' || unit.realDtlCd === 'failRate') { // 감소 목표
      performance = checkNullArray(contents, []).map(content => (content.value * 1)).reduce((a, b) => (a + b), 0) / contents.length;
    } else { // 증가 목표
      if (unit.cycle === 'DAY') { // 일 평균 실적
        performance = checkNullArray(contents, []).map(content => (content.value * 1)).reduce((a, b) => (a + b), 0) / contents.length;
      } else if (unit.cycle === 'WEEK') { // 주 평균 실적
        performance = checkNullArray(contents, []).map(content => (content.value * 1)).reduce((a, b) => (a + b), 0) / props.weeks;
      } else if (unit.cycle === 'MONTH') { // 월 평균 실적
        performance = checkNullArray(contents, []).map(content => (content.value * 1)).reduce((a, b) => (a + b), 0);
      }
    }

    let achievement, goalRate;
    if (unit.realDtlCd === 'production' || unit.realDtlCd === '매출') { // 생산량 증가 또는 매출액 증가 (증가목표)
      achievement = BigNumber(performance).minus(BigNumber(current)).dividedBy(BigNumber(current)).multipliedBy(100).toNumber();
      goalRate = BigNumber(performance).dividedBy(target).multipliedBy(100).toNumber();
    } else if (unit.realDtlCd === 'shorten' || unit.realDtlCd === 'failRate') { // 납기 단축 또는 불량률 감소(감소목표)
      achievement = BigNumber(current).minus(BigNumber(performance)).dividedBy(BigNumber(current)).multipliedBy(100).toNumber();
      goalRate = BigNumber(target).dividedBy(performance).multipliedBy(100).toNumber();
    }

    setCurrent(() => { return current; });
    setTarget(() => { return target; });
    setPerformance(() => { return performance; });
    setAchievement(() => { return achievement; });
    setGoalRate(() => { return goalRate; });
  }, [props])

  return (
    <section className='section-table'>
      {checkNullArray(contents, false) && ['production', 'shorten', '매출', 'failRate'].includes(unit.realDtlCd) ? (
        <TableSection content={
          <table>
            <thead>
              <tr><th colSpan={2}>KPI</th></tr>
            </thead>
            <tbody>
              <tr>
                <th>현재 {(unit.cycle === 'DAY' ? `(일)` : unit.cycle === 'WEEK' ? `(주)` : '(월)')}</th>
                <td>{checkEmptyNull(_current, '').toLocaleString()}</td>
              </tr>
              <tr>
                <th>목표 {(unit.cycle === 'DAY' ? `(일)` : unit.cycle === 'WEEK' ? `(주)` : '(월)')}</th>
                <td>{checkEmptyNull(_target, '').toLocaleString()}</td>
              </tr>
              <tr><th>{(unit.cycle === 'DAY' ? `일` : unit.cycle === 'WEEK' ? `주` : '월')} 평균 실적</th><td>{checkEmptyNull(_performance, '').toLocaleString()}</td></tr>
              <tr><th>향상률</th><td>{checkEmptyNull(_achievement, '').toLocaleString()} %</td></tr>
              <tr><th>목표달성률</th><td>{checkEmptyNull(_goalRate, '').toLocaleString()} %</td></tr>
            </tbody>
          </table>
        } />
      ) : null}
      <TableSection content={
        <table>
          <thead>
            <tr>
              <th>일자</th><th>{unit.unit}</th>
            </tr>
          </thead>
          <tbody>
            {!checkNullArray(contents, false) ? (<>
              <tr>
                <td colSpan={2}>데이터가 없습니다</td>
              </tr>
            </>) : (<>
              {checkNullArray(contents, []).map((data, index) => {
                return (
                  <tr key={index + '_data'}>
                    <td>{data.occurrenceDate}</td><td>{(checkEmptyNull(data.value, 0) * 1).toLocaleString()}</td>
                  </tr>
                );
              })}
            </>)}
          </tbody>
        </table>
      }
      />
    </section>
  );
};

export default KPITable;