import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import { mallSiteUserActions_setUser } from 'store/modules/actions/mallSite/mallSiteUserActions';
import { menuActions_setCompanyMenu } from 'store/modules/actions/user/menuActions';
import { operatorActions_setIfisPageCode } from 'store/modules/actions/operator/operatorActions';
import { userReducer_reset, userReducer_setCompany, userReducer_setUser, userReducer_setUserElementType, userReducer_setUserEndDateName, userReducer_setUserMenuCode, userReducer_setUserPutName, userReducer_setUserStockType, } from 'store/modules/actions/user/userActions';

import { getCookie, removeCookie, setCookie } from 'preferences/cookie/cookie';
import { GOOGLE_AUTH_URL } from 'preferences/server/constants';
import { auth } from 'api/apis/auth/auth';
import { companyPageCode } from 'api/apis/operator/companyPageCode';
import { companyMenu } from 'api/apis/operator/companyMenu';

import { checkEmptyNull, checkNull, checkNullArray, checkNullObject, checkNullParse } from 'components/checkValues/checkValues';
// import { checkPw } from 'components/checkValues/sign';
import { LoginComponent } from './Auth.style';

const Login = () => {
  /* ====================================================================== #1 */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { menuReducer, operatorReducer, userReducer } = useSelector((state) => state);

  /* ====================================================================== #2 */
  const [_loginId, setLoginId] = useState('');
  const [_pwStatus, setPwStatus] = useState(false);
  const [_pw, setPw] = useState('');

  const [_enterStatus, setEnterStatus] = useState(false); // 접속 가능 상태?

  /* ====================================================================== #3 */
  useEffect(() => {
    if (!checkEmptyNull(getCookie('accessToken'), false) && !checkEmptyNull(getCookie('refreshToken'), false)) {
      // accessToken X
      return console.error('No access token set.');
      // return console.error('No access token set.');
    } else if (checkEmptyNull(getCookie('accessToken'), false) && checkEmptyNull(getCookie('refreshToken'), false) && !checkNullObject(userReducer.user, false)) {
      // accessToken O - userData X
      (async () => {
        console.log('useEffect');
        const result = await loadCurrentlyLoggedInUser();
        if (result) setEnterStatus(() => { return true })
      })();
    } else {
      // accessToken O - userData O
      checkIfisUser();
    }
  }, [getCookie('accessToken')]); // accessToken 쿠키 값 주시


  useEffect(() => { /* checkPassword(); */ return () => { } }, [_pw]); // 비밀번호 주시
  useEffect(() => { /* checkPassword(); */ return () => { } }, [_enterStatus]); // 접속 가능 상태 주시

  /* ====================================================================== #4 */
  const loadCurrentlyLoggedInUser = (token) => {
    return new Promise(async (resolve) => {
      console.log('_enterStatus : ', _enterStatus);
      if (_enterStatus) return resolve(true);
      const actError = () => {
        // 쿠키, 리듀서 초기화
        console.log('================================= loadCurrentlyLoggedInUser - actError');
        dispatch(userReducer_reset());
        dispatch(mallSiteUserActions_setUser());

        removeCookie('accessToken');
        removeCookie('refreshToken');

        setLoginId(() => { return '' });
        setPw(() => { return '' });

        navigate('/', { replace: true });
      }

      const headers = { headers: { Authorization: checkEmptyNull(token, getCookie('accessToken')) } };
      const alertText = `
부여받은 권한이 없습니다.
관리자에게 문의해주세요.
      `;

      // userData X
      await axios.get('/any/oneSelf', headers).then(async (response) => {
        console.log('/any/oneSelf - response : ', response);
        if (response === undefined) return actError();

        // userData 설정
        const teamRoleList = {};
        const userData = { ...response.data, userId: response.data.id, mallUserName: response.data.name }
        if (checkNullObject(response.data.team, false)) {
          userData.team = {
            ...response.data.team,
            teamRoleList: checkNullArray(response.data.team.teamRoleList, []).map(teamRole => {
              teamRoleList[teamRole.pageCode] = teamRole;
              return { ...teamRole, authority: teamRole.pageCode + '-' + teamRole.authority }
            })
          }
        }

        // 접속 가능 여부 판단
        console.log('userData : ', userData);
        console.log("userData.role === 'ROLE_GUEST' : ", userData.role === 'ROLE_GUEST');
        console.log('!checkEmptyNull(userData.companyId, false) : ', !checkEmptyNull(userData.companyId, false));
        console.log("userData.provider === 'google' : ", userData.provider === 'google');
        if (userData.role === 'ROLE_GUEST' && !checkEmptyNull(userData.companyId, false) && userData.provider === 'google') {
          console.log('goggle register');
          // 구글 로그인 + 회사가 없는 유저
          //  => 회사 가입 페이지로 이동
          return setTimeout(navigate(`/companySelect/google`, { replace: true }), 1000);
        } else if (userData.role === 'ROLE_GUEST' && !checkEmptyNull(userData.companyId, false) && userData.provider === 'local') {
          console.log('goggle register');
          // 로컬 로그인 + 회사가 없는 유저
          //  => 회사 가입 페이지로 이동
          return setTimeout(navigate(`/companySelect`, { replace: true }), 1000);
        } else if (userData.role === 'ROLE_MALL') {
          // 몰 가입 유저
          alert(alertText);
          return actError();
        } else if (userData.role === 'ROLE_USER' && checkEmptyNull(userData.companyId, false) && !checkEmptyNull(userData.team, false)) {
          // 회사 O + 팀이 없는 유저
          alert(alertText);
          return actError();
        } else if ((userData.role === 'ROLE_MASTER' || userData.role === 'ROLE_ADMIN' || userData.role === 'ROLE_USER') && checkEmptyNull(userData.companyId, false)) {
          // 회사 O
          // => 회사 데이터 호출
          const result_getCompanyPageCode = await getCompanyPageCode();
          const result_getMyCompany = await getMyCompany(userData);
          const result_getCompanyMenu = await getCompanyMenu(userData);
          console.log(result_getCompanyPageCode, result_getMyCompany, result_getCompanyMenu, result_getCompanyPageCode && result_getMyCompany && result_getCompanyMenu);
          if (result_getCompanyPageCode && result_getMyCompany && result_getCompanyMenu) resolve(true);
          dispatch(userReducer_setUser(userData));
          dispatch(mallSiteUserActions_setUser(userData));
          dispatch(userReducer_setUserMenuCode(teamRoleList));
        }
      }).catch((error) => {
        // 에러 발생
        console.log('error : ', error);
        alert('다시 접속해주시기 바랍니다.');
        actError();
      });
    })
  };
  const getCompanyPageCode = () => {
    return new Promise(async (resolve) => {
      await companyPageCode.getCompanyPageCodes().then(response => {
        if (!checkNull(response, false)) return;
        console.log('companyPageCodeApi.getCompanyPageCodes : ', response);
        // 아이피스 전체 메뉴 코드
        const IfisPageCode = {};
        response.data.content.forEach(pageCode => {
          IfisPageCode[pageCode.pageCode] = pageCode;
        })
        dispatch(operatorActions_setIfisPageCode(IfisPageCode));
        if (checkNullObject(IfisPageCode, false)) resolve(true);
      })
    })
  }
  const getMyCompany = async (userData) => {
    return new Promise(async (resolve) => {
      await axios.get('/api/company/' + userData.companyId, { headers: { Authorization: getCookie('accessToken') } }).then(async (response) => {
        if (response === undefined) return;
        console.log('companyApi.getCompany - response : ', response);
        const companyData = response.data;
        const myCompanyData = {
          companyId: companyData.companyId,
          companyName: companyData.companyName,
          companyCode: companyData.companyCode,
          adminEmail: companyData.adminEmail,

          elementTypes: checkNullParse(companyData.elementTypes, []),
          stockTypes: checkNullParse(companyData.stockTypes, []),

          companyPageCodes: checkNullParse(companyData.companyPageCodes, []),
          pageUrl: checkNullParse(companyData.pageUrl, {}),
        };
        dispatch(userReducer_setCompany(myCompanyData));
        setCustomData(userData, myCompanyData);
        if (checkNullObject(myCompanyData, false)) resolve(true);
      }).catch((error) => {
        console.log('companyApi.getCompany - error : ', error);
      })
    })
  }
  const getCompanyMenu = (userData) => {
    return new Promise(async (resolve) => {
      const BodyToPost = { companyId: userData.companyId };
      await companyMenu.searchCompanyMenu(BodyToPost).then(response => {
        if (!checkNullObject(response, false)) return;
        console.log('companyMenuApi.searchCompanyMenu : ', response);
        // 회사 메뉴 코드
        const companyMenu = {};
        response.data.content.forEach(menu => { companyMenu[menu.pageCode] = menu })
        dispatch(menuActions_setCompanyMenu(companyMenu));
        if (checkNullObject(companyMenu, false)) resolve(true)
      })
    })
  }
  const setCustomData = (userData, companyData) => {
    /* 물품유형 */
    dispatch(userReducer_setUserElementType(companyData.elementTypes));

    /* 재고처리 */
    dispatch(userReducer_setUserStockType(companyData.stockTypes.stockTypes),);

    /* 커스텀 */
    const pageCustom = checkNullObject(companyData.pageUrl.pageCustom, false);
    if (pageCustom) {
      const customPutName = checkNull(companyData.pageUrl.pageCustom['303'], false);
      if (customPutName) { dispatch(userReducer_setUserPutName(customPutName)) }

      const customEndDate = checkNull(companyData.pageUrl.pageCustom['201'], false);
      if (customEndDate) {
        const endDate = customEndDate.split('/');
        dispatch(userReducer_setUserEndDateName(endDate));
      }
    }
  }

  /* ====================================================================== #5 */
  const checkIfisUser = async () => {
    if (userReducer.user.role === 'ROLE_MALL') {
      // console.log('** not here! **')
      return navigate(`/${userReducer.user.companyId}/mall/sign/login`, { replace: true })
    } else if (!checkEmptyNull(userReducer.user.companyId, false)) {
      // console.log('** checkIfisUser - no company **');
      setTimeout(navigate(`/companySelect/google`, { replace: true }), 1000);
    } else if (!checkNullObject(userReducer.company, false)) {
      // console.log('** checkIfisUser - no companyData **');
      (async () => {
        const result = await loadCurrentlyLoggedInUser();
        if (result) setEnterStatus(() => { return true })
      })();
    } else {
      if (!_enterStatus) {
        if (!checkNullObject(userReducer.user, false) || !checkNullArray(operatorReducer.IfisPageCode, false) || !checkNullArray(menuReducer.companyMenu, false)) {
          (async () => {
            console.log('checkIfisUser');
            const result = await loadCurrentlyLoggedInUser();
            if (result) setEnterStatus(() => { return true })
          })();
        } else {
          setEnterStatus(() => { return true })
        }
      }
    }
  }

  // const checkPassword = () => {
  //   setPwStatus(() => { return checkPw(_pw, _pw); })
  // }
  const actLogin = async () => {
    if (!checkEmptyNull(_loginId, false)) return alert('아이디를 확인해주세요.');
    if (!checkEmptyNull(_pw, false)) return alert('비밀번호를 확인해주세요.');
    // if (_pw.length < 8 || !_pwStatus) return alert('비밀번호를 확인해주세요.');
    // const reg = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/;
    // if (!reg.test(_pw)) return alert('비밀번호는 최소 8자리 이상 영문 + 숫자 + 특수기호 조합입니다.');
    const BodyToPost = { loginId: _loginId, password: _pw };
    await auth.login(BodyToPost).then(async (response) => {
      if (response === undefined) return;
      console.log('auth.login : ', response);
      const options = {/* httpOnly: true */ };
      setCookie('accessToken', response.data.accessToken, options);
      setCookie('refreshToken', response.data.refreshToken, options);
      setCookie('tokenTime', moment().format('YYYY-MM-DD HH:mm:ss'), options);
      const result = await loadCurrentlyLoggedInUser();
      if (result) setEnterStatus(() => { return true })
    })
  };


  return (
    <LoginComponent>
      {_enterStatus ? <>
        <section className='sign'>
          <div className='signTitle'>
            <span>&ensp;</span>
            <img src={process.env.PUBLIC_URL + '/logo_Ifis.svg'} alt="아이피스 로고" style={{ verticalAlign: 'middle', marginInlineEnd: '70px' }}  />
            {/* <h3>아이피스</h3> */}
          </div>
          <button type="submit" className='submit' onClick={(e) => {
            e.preventDefault();
            if (_enterStatus) {
              navigate(`/dashboard`, { replace: true })
            } else {
              alert('잠시만 기다려주세요.');
              getMyCompany(userReducer.user);
            }
          }}>접속하기</button>
        </section>
      </> : <>
        <section className='sign'>
          <div className='signTitle'>
            <img src={process.env.PUBLIC_URL + '/logo_Ifis.svg'} alt="아이피스 로고" />
            {/* <h3>아이피스 로그인</h3> */}
            <h3>로그인</h3>
          </div>
          <fieldset>
            <input
              type="text"
              placeholder="아이디"
              required
              value={_loginId}
              onInput={(e) => {
                const loginId = e.target.value;
                setLoginId(() => { return loginId; });
              }}
            />
            <input
              type="password"
              name="password"
              minLength={8}
              required
              placeholder="비밀번호 (8자리 이상 영문+숫자+특수기호)"
              value={_pw}
              onInput={(e) => {
                const pw = e.target.value;
                setPw(() => { return pw; });
              }}
            />
          </fieldset>
          <div>
            <button type="submit" className='submit' onClick={actLogin}>로그인</button>
            <button type="submit" className='submit google' onClick={() => { window.location = GOOGLE_AUTH_URL }}>구글 로그인</button>
          </div>
          <div className='signitem'>
            <button onClick={() => { navigate(`/sign/email/find`, { replace: true }); }} >아이디 찾기</button>
            <button onClick={() => { navigate(`/sign/pw/find`, { replace: true }); }} >비밀번호 찾기</button>
            <button onClick={() => { navigate(`/sign/join `, { replace: true }); }} >회원가입</button>
          </div>
        </section>
      </>
      }
    </LoginComponent>
  );
};

export default Login;
