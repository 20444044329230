import React, { useEffect, useState  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { clusterActions_setCompanyList, clusterActions_setUserData } from 'store/modules/actions/cluster/clusterActions';
import { pageReducer_getCurrentPage } from 'store/modules/actions/default/pageActions';

import { getCookie } from 'preferences/cookie/cookie';
import { companyApi } from 'api/apis/companyApi';

import { ClusterMainComponent } from 'platform/cluster/styles/main.style';
import ClusterHeader from 'platform/cluster/components/ClusterHeader';
import ClusterSideBar from '../components/ClusterSideBar';

export default function ClusterMain({ children }) {
  const location = useLocation();

  const [clusterId, setClusterId] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clusterReducer } = useSelector((state) => state);

  const clusterPath = clusterId ? `/${clusterId}/platform/cluster` : location.pathname.split('/platform/cluster')[0] + '/platform/cluster';

  const [loading, setLoading] = useState(true); // 로딩 상태 추가

  useEffect(() => {
    const splits = location.pathname.split('/');
    const last = splits[splits.length - 1];
    if (last === 'cluster') return navigate(clusterPath, { replace: true });
  }, []);
  useEffect(() => {
    dispatch(pageReducer_getCurrentPage(window.location.pathname));
    if (!getCookie('accessToken')) return navigate(clusterPath + '/sign/login');
    getClusterCompanyList();
    getMyData();
  }, [location.pathname]);
  useEffect(() => {
    if (getCookie('accessToken')) {
      console.log('clusterReducer.user : ', clusterReducer.user);
    }
  }, [clusterReducer.user]);

  const getClusterCompanyList = async () => { // 소공인 리스트 불러오기
    const id = Number(clusterId.replace('/', ''));
    const body = { clusterId: !isNaN(id) ? id : '' };
    await companyApi.searchCompanies('', body).then((response) => {
      if (response === undefined) return;
      dispatch(clusterActions_setCompanyList(response.data));
    });
  };

  useEffect(() => {
    if (clusterId) {
      const newClusterPath = `/${clusterId}/platform/cluster`;
      setTimeout(() => {
        navigate(newClusterPath, { replace: true });
      }, 1000);
    }
    
  }, [clusterId]); // clusterId가 변경될 때마다 실행

  const getMyData = async () => {
    // 소공인 정보 불러오기
    const headers = { headers: { Authorization: getCookie('accessToken') } };
    await axios
      .get('/any/oneSelf', headers)
      .then(async (response) => {
        if (response === undefined) return;
        const userData = response.data;
        console.log('myData : ', response);
        dispatch(clusterActions_setUserData(userData));
        if (userData.companyId) {
          try {
            const companyResponse = await companyApi.getCompany(
              userData.companyId,
            );
            if (companyResponse && companyResponse.data) {
              const companyData = companyResponse.data;

              const newClusterId = companyData.cluster.clusterId;

              // clusterId 없는 경우 로그인 페이지로 이동
              if (!newClusterId) {
                console.log('clusterId 없음');
                navigate(`${clusterPath}/sign/login`, { replace: true });
                return;
              }

              console.log('설정된 clusterId : ', newClusterId);
              setClusterId(newClusterId); // clusterId 업데이트
            } else {
              console.log('companyId 없음');
            }
          } catch (error) {
            console.log('company data 에러:', error);
          } finally {
            setLoading(false); // 데이터 로딩 완료 후 로딩 상태를 false로 설정
            console.log(clusterPath);
          }
        }
      }).catch((error) => {
        console.log('user data 에러:', error);
      });
  };

  // 로딩 중일 때는 화면을 렌더링하지 않거나, 로딩 화면을 표시
  if (loading) {
    return <div>Loading...</div>; // 또는 로딩 스피너를 추가
  }

  return (
    <ClusterMainComponent>
      <ClusterHeader />
      <section className="main">
        <ClusterSideBar />
        <Outlet {...children} />
      </section>
    </ClusterMainComponent>
  );
};
